import React, { useState, useEffect } from "react";
import { Tile, Grid, Column, Tag, Layer, Loading } from "@carbon/react";
import {
  StarFilled,
  WarningFilled,
  CheckmarkFilled,
} from "@carbon/icons-react";
import ProductTile from "../ProductTile/ProductTile";

function ProductCompare({
  compareWith,
  setCompareWith,
  relatedProducts,
  setRelatedProducts,
}) {
  const [compareWithLoading, setCompareWithLoading] = useState(false);
  const [compareWithReviews, setCompareWithReviews] = useState([]);
  const BACKEND_ENDPOINT = '';
  // Fetching the price from the parent element

  const keyMappings = {
    "color_-_appliance_cabinet": "Color",
    price: "Price",
    energy_efficiency_tier_rating: "Energy Efficiency",
    "depth_(excluding_handles)": "Depth (excluding handles)",
    // Add other key mappings as needed
  };

  const specElements = Object.entries(keyMappings).map(
    ([specKey, displayName]) => {
      let value;
      if (specKey === "price") {
        value = `$${compareWith?.price || "N/A"}`;
      } else {
        value = compareWith?.specs?.[specKey] || "N/A";
      }
      return {
        key: displayName,
        value,
      };
    }
  );

  const relatedTileData = relatedProducts?.map(
    (product, index) => ({
      id: index + 1,
      uid: product.id,
      imageUrl: product.image,
      name: product.title,
      description: product.description,
      rating: product.rating || 0,
      price: product.price,
      brand: product.brand,
    })
  );

  useEffect(() => {
    localStorage.setItem("isCompare", "yes");

    async function fetchData() {
      //if (compareWith && compareWith.image) {
      setCompareWithLoading(true);
      try {
        if (compareWith !== null) {
          setCompareWithLoading(true);
          try {
            const response = await fetch(
              `${BACKEND_ENDPOINT}/api/assistant/query`,
              {
                method: "POST",
                redirect: "follow",
                headers: {
                  "content-type": "application/json",
                  accept: "application/json",
                },
                body: JSON.stringify({
                  collection: "ProductReviews",
                  queryParams: {
                    count: 10000,
                    filter: `product_number: ${compareWith.id}`,
                    sort: "-enriched_body.sentiment.score",
                    _return: [
                      "product_number",
                      "title",
                      "body",
                      "enriched_body.sentiment",
                      "enriched_body.keywords",
                      "average_rating",
                      "average_rating",
                    ],
                  },
                }),
              }
            );
            const result = await response.json();
            setCompareWithReviews(result?.results || []);
            //setCompareWithLoading(false);
          } catch (error) {
            console.error(error);
            setCompareWithReviews([]);
            // setCompareWithLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        setCompareWithLoading(false);
      }
      //}
    }

    fetchData();
  }, [compareWith]);

  if (!compareWith || !compareWith.image) {
    return null; // Render null or a placeholder while data is loading or if compareWith is null
  }

  return (
    <Grid narrow>
      <Column sm={2} md={4} lg={12}>
        {compareWithLoading ? (
          <Loading />
        ) : (
          <Tile className="product-reviews-desc-tile">
            <Grid narrow>
              <Column sm={2} md={2} lg={4}>
                <img
                  src={compareWith.image}
                  alt={compareWith.name}
                  width="250px"
                />
              </Column>
              <Column sm={2} md={4} lg={8}>
                <div className="product-desc-tile">
                  <h3>{compareWith.brand + " " + compareWith.title}</h3>
                  <div className="product-price-rating">
                    <h2 className="product-price">{"$" + compareWith.price}</h2>
                    <div className="product-rating">
                      <Tag type="blue" title="Clear Filter">
                        <div>
                          <StarFilled />
                          {compareWith.hasOwnProperty("rating")
                            ? compareWith.rating
                            : "4.5"}
                        </div>
                      </Tag>
                    </div>
                  </div>
                  <div className="product-specs">
                    <Grid narrow>
                      {specElements.map(({ key, value }) => (
                        <Column key={key} sm={2} md={4} lg={4}>
                          <div className="incident-details-field">
                            <strong>{key}</strong>
                            <br />
                            <span>{value}</span>
                          </div>
                        </Column>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Column>
            </Grid>
          </Tile>
        )}
      </Column>

      <Column sm={2} md={4} lg={12}>
        {compareWithLoading ? (
          <Loading />
        ) : (
          <div className="product-compare-tile">
            <h5>
              Compare {compareWith.brand} {compareWith.title} with
            </h5>
            <Grid className="product-tiles">
              {" "}
              {compareWithLoading ? (
                <p>Loading...</p>
              ) : (
                relatedTileData.map((tile, index) => (
                  <Column sm={4} md={3} lg={4}>
                    {/* <Link to={`/product/${index + 1}`}> */}
                    <ProductTile key={index} {...tile} />
                    {/* </Link> */}
                  </Column>
                ))
              )}
            </Grid>
          </div>
        )}
      </Column>
      {/* <button style={{ display: 'none' }} id="hiddenBtnProdQuery">Hidden Button</button> */}
    </Grid>
  );
}

export default ProductCompare;
