import React from "react";
import {
  Grid,
  Column,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  BreadcrumbItem,
  UnorderedList,
  ListItem,
  Tile,
} from "@carbon/react";
import PageHeader from "../../components/PageHeader/PageHeader";

function Homepage({ setQuery }) {
  setQuery("");
  return (
    <div>
      {/* {redirect ? <ProductsPage /> : */}
      <Grid narrow>
        <Column sm={4} md={8} lg={16}>
          <PageHeader
            headerTitle={"Intelligent Marketplace"}
            isProductsPage={false}
            isProductDescPage={false}
          />
          <Tabs>
            <TabList aria-label="List of tabs">
              <Tab>All Categories</Tab>
              <Tab disabled>Today's Deals</Tab>
              <Tab disabled>Best Selling</Tab>
              <Tab disabled>New Releases</Tab>
              <Tab disabled>Featured</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div className="homepage-container">
                  <Grid narrow>
                    <Column sm={4} md={6} lg={12}>
                      <Tile>
                        {/* <h3>Find anything in our catalog, just ask</h3> <br/> */}
                        <h5>
                        Explore our catalog effortlessly by simply asking for what you need using the webchat in the right corner! For example, type:
                        </h5>
                        <UnorderedList nested className="list">
                          <ListItem>I'm looking for a washing machine</ListItem>
                          <ListItem>I need a mini fridge</ListItem>
                        </UnorderedList>
                        <h6>Please note that the experience is for demonstration purposes only and is limited to a few specific 
examples outlined in the guide on the right.</h6>
                      </Tile>
                    </Column>

                    <Column sm={4} md={6} lg={12}>
                      <h5>Recommended Categories</h5>
                    </Column>
                    <Column sm={4} md={6} lg={4}>
                    </Column>
                    <Column sm={2} md={3} lg={4}>
                      <Tile className="washing-machine">
                        <h6>Washing Machines</h6>
                        <br />
                        <img width={250} height={260}
                          src={
                            "https://images.thdstatic.com/productImages/bf03a171-f111-4ced-932f-c4779eaf8699/svn/blue-sapphire-ge-front-load-washers-gfw850spnrs-64_600.jpg"
                          }
                        ></img>
                      </Tile>
                    </Column>

                    <Column sm={2} md={3} lg={4}>
                      <Tile>
                        <h6>Refrigerators </h6>
                        <br />
                        <img width={250} height={260}
                          src={
                            "https://images.thdstatic.com/productImages/f187cfc6-f4c6-4195-95e1-ad21b18c38a5/svn/fingerprint-resistant-stainless-steel-samsung-french-door-refrigerators-rf23r6201sr-64_600.jpg"
                          }
                        ></img>
                      </Tile>
                    </Column>


                    <Column sm={2} md={3} lg={4}>
                      <Tile>
                        <h6>Ovens</h6>
                        <br />
                        <img width={250} height={260}
                          src={
                            "https://images.thdstatic.com/productImages/64c512b7-69d0-47fd-a4a9-a2a535a0d326/svn/black-summit-appliance-single-gas-wall-ovens-ttm7212dk-64_600.jpg"
                          }
                        ></img>
                      </Tile>
                    </Column>
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel>Tab Panel 2</TabPanel>
              <TabPanel>Tab Panel 3</TabPanel>
              <TabPanel>Tab Panel 4</TabPanel>
            </TabPanels>
          </Tabs>
        </Column>
      </Grid>
      {/* } */}
      {/* {redirect && } Render ProductsPage conditionally */}
    </div>
  );
}

export default Homepage;
