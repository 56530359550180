import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Tile,
  Grid,
  Column,
  Loading,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@carbon/react";
import {
  StarFilled,
  Delivery,
  Currency,
  DeliveryParcel,
  ManageProtection,
  Trophy,
  LocationFilled,
} from "@carbon/icons-react";
import productsData from "../ProductsPage/jsonData.json";
import PageHeader from "../../components/PageHeader/PageHeader";
import ProductReview from "../../components/ProductReview/ProductReview";
import ProductCompare from "../../components/ProductCompare/ProductCompare";
import ProductRelated from "../../components/ProductRelated/ProductRelated";
import ProductSearchResult from "../../components/ProductSearchResult/ProductSearchResult";

function ProductDescPage(query, onReqeust, setQuery) {

  const BACKEND_ENDPOINT = '';
  // const BACKEND_ENDPOINT = 'https://retail-demo.140j8e66l875.us-east.codeengine.appdomain.cloud';

  let params = useParams();
  let [searchParams] = useSearchParams();
  let userQuery = query.query;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [productLoading, setProductLoading] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [specEntries, setSpecEntries] = useState([]);
  const [selectedProductReviews, setSelectedProductReviews] = useState({});
  const [compareWith, setCompareWith] = useState(null);

  const [relatedProducts, setRelatedProducts] = useState(null);
  const [relatedProductsLoading, setRelatedProductsLoading] = useState(null);
  const [productQuery, setProductQuery] = useState("");
  const [productQueryLoading, setProductQueryLoading] = useState(false);
  const [productQueryResults, setProductQueryResults] = useState([]);
  const [queryDescResult, setQueryDescResult] = useState("");

  //const [productQuery, setProductQuery] = useState(searchParams.get('query'));

  const [isSearchResult, setIsSearchResult] = useState(true);

  const icons = {
    Delivery: {
      icon: Delivery,
      className: "delivery-icon",
      label: "Fast Delivery",
    },
    Currency: {
      icon: Currency,
      className: "currency-icon",
      label: "Pay On Delivery",
    },
    DeliveryParcel: {
      icon: DeliveryParcel,
      className: "delivery-parcel-icon",
      label: "No Returns",
    },
    ManageProtection: {
      icon: ManageProtection,
      className: "manage-protection-icon",
      label: "1-Year Warranty",
    },
    Trophy: {
      icon: Trophy,
      className: "trophy-icon",
      label: "Top Quality",
    },
  };
  const specKeyMapping = {
    "depth_(excluding_handles)": "Depth (excluding handles)",
    "depth_(including_handles)": "Depth (including handles)",
    "depth_(less_door)": "Depth (less door)",
    "depth_with_door_open_90_degrees_(in)": "Depth with door open 90 degrees",
    "height_to_top_of_door_hinge_(in.)": "Height to top of door hinge",
    "height_to_top_of_refrigerator_(in.)": "Height to top of refrigerator",
    "product_depth_(in.)": "Product depth",
    "product_height_(in.)": "Product height",
    "product_width_(in.)": "Product width",
    "refrigerator_width_(in.)": "Refrigerator width",
    appliance_category: "Appliance category",
    appliance_type: "Appliance type",
    "built-in_and_standard_type": "Built-in and standard type",
    "color_-_appliance_cabinet": "Color - Appliance cabinet",
    "color_-_door_handle(s)": "Color - Door handle(s)",
    "color/finish": "Color/Finish",
    "color/finish_family": "Color/Finish family",
    defrost_type: "Defrost type",
    door_finish: "Door finish",
    "energy_consumption_(kwh/year)": "Energy consumption (kWh/year)",
    energy_efficiency_tier_rating: "Energy efficiency tier rating",
    "freezer_capacity_(cu._ft.)": "Freezer capacity (cu. ft.)",
    freezer_door_type: "Freezer door type",
    freezer_features: "Freezer features",
    freezer_type: "Freezer type",
    general_features: "General features",
    ice_maker_features: "Ice maker features",
    ice_maker_type: "Ice maker type",
    "ice/water_dispenser": "Ice/Water dispenser",
    "indoor/outdoor": "Indoor/Outdoor",
    installation_depth: "Installation depth",
    number_of_doors: "Number of doors",
    "number_of_freezer_baskets/bins": "Number of freezer baskets/bins",
    number_of_freezer_shelves: "Number of freezer shelves",
    number_of_refrigerator_shelves: "Number of refrigerator shelves",
    operational_features: "Operational features",
    "product_weight_(lb.)": "Product weight (lb.)",
    refrigeration_dispenser_features: "Refrigeration dispenser features",
    "refrigerator_capacity_(cu._ft.)": "Refrigerator capacity (cu. ft.)",
    returnable: "Returnable",
    "shelf_material_-_freezer": "Shelf material - Freezer",
    "shelf_material_-_refrigerator": "Shelf material - Refrigerator",
    storage_features: "Storage features",
    temperature_control_type: "Temperature control type",
    "total_capacity_(cu._ft.)": "Total capacity (cu. ft.)",
    "water_filter_replacement_model#": "Water filter replacement model#",
    certifications_and_listings: "Certifications and listings",
    manufacturer_warranty: "Manufacturer warranty",
  };
  // const handleTabChange = (evt, check) => {
  //   if (check !== 2) {
  //     setSelectedIndex(check);
  //   }
  // };
  // const handleTabChange = (evt, check) => {
  //   if (check) {
  //     setSelectedIndex(check);
  //   }
  //   else setSelectedIndex(evt.selectedIndex);
  // };

  const fetchRelatedProducts = async (product) => {
    const breadcrumbs = product.breadcrumbs.split(",");
    if (breadcrumbs.length) {
      const body = {
        input: {
          text: breadcrumbs[breadcrumbs.length - 1],
          message_type: "text",
          options: {
            alternate_intents: false,
            return_context: true,
            debug: false,
          },
        },
        output: {},
        context: {
          global: {
            system: {
              user_id: "anonymous",
            },
          },
          skills: {
            "main skill": {
              user_defined: {
                initConversation: true,
                locale: "en",
                channel: "WEB",
                save_in_db: false,
                next_action: {
                  action: "search_discovery",
                  collection: "Products",
                },
              },
            },
          },
        },
      };

      setRelatedProductsLoading(true);
      try {
        const response = await fetch(
          `${BACKEND_ENDPOINT}/api/assistant/post`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(body),
          }
        );
        const data = await response.json();
        setRelatedProductsLoading(false);
        if (data?.output?.generic[0]?.results?.products) {
          setRelatedProducts(data.output.generic[0].results.products);
        }
      } catch (error) {
        console.error("Error fetching related products:", error);
        setRelatedProductsLoading(false);
      }
    }
  };
  const prodQueryFunc = () => {
    console.log(
      "in prodQueryFunc 1",
      "usQ",
      userQuery,
      "PsQ",
      productQuery,
      localStorage.getItem("product_query_resp")
    );

    if (userQuery && userQuery !== productQuery) {
      const resp = localStorage.getItem("product_query_resp");

      // Fetch product reviews
      console.log(
        "in prodQueryFunc",
        productQuery,
        localStorage.getItem("product_query_resp")
      );
      let curr_prod_query = productQuery;

      setProductQuery(userQuery);
      setProductQueryLoading(true);
      setProductQueryResults([]);

      let product_query_resp,
        product_query_results = [];
      if (resp) {
        console.log("resp -> ", resp);
        product_query_resp = JSON.parse(resp);
        product_query_results = product_query_resp.reviews.top_positive.concat(
          product_query_resp.reviews.top_negative
        );
        let desc = product_query_resp.product_description;
        desc = product_query_resp.product_description.replace("<em>", "<mark>");
        desc = desc.replace("</em>", "</mark>");

        setProductQueryResults(product_query_results);
        setQueryDescResult(desc);
        if (desc) {
          setIsSearchResult(false);
          //setSelectedIndex(2);
        }

        setProductQueryLoading(false);
        // ... other state updates
        localStorage.removeItem("product_query_resp");
      }
    }
    console.log("productQueryResults", productQueryResults);
  };
  useEffect(
    () => {
      // ... (existing code with useEffect for data fetching)
      //setProductQuery(userQuery);
      const hiddenBtnProdQuery = document.getElementById("hiddenBtnProdQuery");
      if (hiddenBtnProdQuery) {
        hiddenBtnProdQuery.addEventListener("click", prodQueryFunc);
      }

      return () => {
        // Clean up event listener on unmount
        if (hiddenBtnProdQuery) {
          hiddenBtnProdQuery.removeEventListener("click", prodQueryFunc);
        }
      };
    },
    [
      /* dependencies */
    ]
  );
  useEffect(() => {
    const fetchPro = async () => {
      try {
        setProductLoading(true);
        const filters = {
          where: {
            id: params.id,
          },
        };

        const productResponse = await fetch(
          `${BACKEND_ENDPOINT}/api/products?filter=${encodeURI(
            JSON.stringify(filters)
          )}`,
          {
            method: "GET",
            redirect: "follow",
            headers: {
              accept: "application/json",
            },
          }
        );
        const productResult = await productResponse.json();
        setProduct(productResult[0]);
        fetchRelatedProducts(productResult[0]);
        setProductLoading(false);

        const reviewsResponse = await fetch(
          `${BACKEND_ENDPOINT}/api/assistant/query`,
          {
            method: "POST",
            redirect: "follow",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              collection: "ProductReviews",
              queryParams: {
                count: 1000,
                //filter: `product_number: 305026442`,
                filter: `product_number: ${params.id}`,
                sort: "-enriched_body.sentiment.score",
                _return: [
                  "product_number",
                  "title",
                  "body",
                  "enriched_body.sentiment",
                  "enriched_body.keywords",
                  "average_rating",
                ],
              },
            }),
          }
        );
        const reviewsResult = await reviewsResponse.json();
        setSelectedProductReviews({ reviews: reviewsResult?.results });
        setReviewsLoading(false);

        // Update spec entries
        const specs = productResult[0]?.specs || {};
        const entries = Object.entries(specs).map(([key, value]) => {
          const userFriendlyKey = specKeyMapping[key] || key;
          return [userFriendlyKey, value];
        });
        setSpecEntries(entries);
      } catch (error) {
        console.error("Error fetching data:", error);
        setProductLoading(false);
        setReviewsLoading(false);
      }
    };

    fetchPro();
  }, [params.id]);

  return (
    <div>
      <Grid narrow>
        <Column sm={4} md={8} lg={16}>
          <PageHeader
            headerTitle={product.title}
            isProductsPage={true}
            isProductDescPage={true}
          />
          {/* <Tabs selectedIndex={selectedIndex} onChange={handleTabChange}> */}
          <Tabs>
            <TabList className="desc-tabs" aria-label="List of tabs" fullWidth>
              <Tab>Description</Tab>
              <Tab>Reviews</Tab>
              <Tab
                onClick={() => {
                  setCompareWith(product);
                }}
              >
                Compare
              </Tab>
              <Tab>Related</Tab>
              {/* <Tab disabled={isSearchResult}>Search Result</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid narrow>
                  <Column sm={2} md={4} lg={12}>
                    <Tile className="desc-tile-1">
                      {productLoading ? (
                        <Loading />
                      ) : (
                        <Grid narrow>
                          <Column sm={2} md={4} lg={6}>
                            <img
                              src={product.image}
                              alt={product.title}
                              width="430px"
                            />
                          </Column>
                          <Column sm={2} md={4} lg={6}>
                            <div className="product-desc-tile">
                              <h3>{product.brand + " " + product.title}</h3>
                              <div className="product-price-rating">
                                <h2 className="product-price">
                                  {"$" + product.price}
                                </h2>
                                <div className="product-rating">
                                  <Tag type="blue" title="Clear Filter">
                                    <div>
                                      <StarFilled />
                                      {product.hasOwnProperty("rating")
                                        ? product.rating
                                        : "4.5"}
                                    </div>
                                  </Tag>
                                </div>
                              </div>
                              <div className="services">
                                {Object.keys(icons).map((iconName) => (
                                  <Tile key={iconName}>
                                    {React.createElement(icons[iconName].icon, {
                                      className: icons[iconName].className,
                                      size: 36,
                                    })}
                                    <h5>{icons[iconName].label}</h5>
                                  </Tile>
                                ))}
                              </div>
                              <div className="product-description">
                                <h5>About this item</h5>
                                <div>{product.description}</div>
                              </div>
                            </div>
                          </Column>
                        </Grid>
                      )}
                    </Tile>
                  </Column>
                  <Column sm={2} md={4} lg={4}>
                    <Tile className="product-delivery">
                      <div style={{ display: "flex" }}>
                        <h4>${product.price}</h4>
                        {/* <div>{"( $" + product.price + "/count)"} </div> */}
                      </div>
                      <div>
                        <div className="free-del">FREE delivery</div>{" "}
                        <div className="del-date">Friday, 17 November </div>
                        Order within 53 mins.
                      </div>
                      <div className="del-to">
                        <LocationFilled size="24" />
                        <div>
                          Delivering to Bengaluru 560002 -{" "}
                          <a>Update location</a>
                        </div>
                      </div>
                    </Tile>
                    <Tile className="add-product">
                      <h4 className="free-del">In stock</h4>
                      <div className="del-date">
                        <div>Sold by </div>{" "}
                        <div>
                          <a>INDETOUCH EXPORTERS ENTERPRISE</a> and Fulfilled by
                          Amazon.{" "}
                        </div>
                      </div>
                      <div>
                        <Tag type="blue" title="Clear Filter">
                          Add to Cart
                        </Tag>
                        <Tag type="green" title="Clear Filter">
                          Buy Now
                        </Tag>
                      </div>
                    </Tile>
                  </Column>
                  <Column sm={2} md={4} lg={12}>
                    <Tile className="product-specs">
                      <h5>Product Specs</h5>
                      <Grid>
                        {specEntries.map(([key, value], index) => (
                          <Column key={index} sm={2} md={4} lg={6}>
                            <div className="incident-details-field">
                              <strong>{key}</strong>
                              <br />
                              <span>{value}</span>
                            </div>
                          </Column>
                        ))}
                      </Grid>
                    </Tile>
                  </Column>
                </Grid>
              </TabPanel>
              <TabPanel>
                <ProductReview
                  product={product}
                  reviews={selectedProductReviews}
                />
              </TabPanel>
              <TabPanel>
                <ProductCompare
                  compareWith={compareWith}
                  setCompareWith={setCompareWith}
                  relatedProducts={relatedProducts}
                  setRelatedProducts={setRelatedProducts}
                />
              </TabPanel>
              {/* if (localStorage.getItem("isCompare")) localStorage.removeItem("isCompare"); */}
              <TabPanel>
                <ProductRelated
                  compareWith={compareWith}
                  setCompareWith={setCompareWith}
                  relatedProducts={relatedProducts}
                  setRelatedProducts={setRelatedProducts}
                  // isSearchResult={isSearchResult}
                />
              </TabPanel>
              {/* <TabPanel>
                {isSearchResult ? (
                  <></>
                ) : (
                  <ProductSearchResult
                    onRequest={onReqeust}
                    product={product}
                    numReviews={selectedProductReviews.length}
                    avgRating={"4"}
                    productQueryResults={productQueryResults}
                    queryDescResult={queryDescResult}
                  />
                )}
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Column>
      </Grid>
      <button
        style={{ display: "none" }}
        id="hiddenBtnProdQuery"
        onClick={prodQueryFunc()}
      >
        Hidden Button
      </button>
    </div>
  );
}

export default ProductDescPage;
