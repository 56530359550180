import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

function HiddenHeader({ query, setQuery }) {
  const [isProduct, setIsProduct] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectProd, setRedirectProd] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.startsWith("/product")) {
      setIsProduct(true);
    } else {
      setIsProduct(false);
    }
  }, [window.location.pathname]);

  const handleNav = (search, filters) => {
    let uri = "";
    if (isProduct) {
      uri = `${window.location.pathname}?query=${encodeURIComponent(search)}`;
    } else {
      uri = `/search/${encodeURIComponent(search)}`;
      if (filters?.ratings.length || filters?.minPrice || filters?.maxPrice){
        uri += `?filters=${encodeURIComponent(JSON.stringify(filters))}`;
        console.log("filter uri -> ", uri)
      }
    }
    navigate(uri);
  };

  const onSearch = (queryString) => {
    let search = queryString;
    if (isProduct) {
      //to check if the page navigates to product list page or product  desc page
      if (query.length > 0) search = `${query}${"+"}${queryString}`;
      setQuery(search);
    }
    handleNav(search);
  };

  const handleHiddenButtonClick = () => {
    setRedirect(true); // Trigger redirection
  };

  const handleHiddenButtonProdClick = () => {
    setRedirectProd(true); // Trigger redirection
  };

  useEffect(() => {
    // Redirect logic for search
    if (redirect) {
      let inp = localStorage.getItem("searchInput") || "";
      onSearch(inp);
      setRedirect(false); // Reset redirect state
    }
  }, [redirect, onSearch]);

  useEffect(() => {
    // Redirect logic for product
    if (redirectProd) {
      let product_id = localStorage.getItem("product_id");
      navigate("/product/" + product_id);
      setRedirectProd(false); // Reset redirectProd state
    }
  }, [redirectProd, navigate]);

  return (
    <div>
      <button
        style={{ display: "none" }}
        id="hiddenBtn"
        onClick={handleHiddenButtonClick}
      >
        Hidden Button
      </button>
      <button
        style={{ display: "none" }}
        id="hiddenBtnProd"
        onClick={handleHiddenButtonProdClick}
      >
        Hidden Button
      </button>
    </div>
  );
}

export default HiddenHeader;
