import { Tile, Tag } from "@carbon/react";
import { StarFilled } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

function ProductTile({
  id,
  uid,
  imageUrl,
  name,
  description,
  rating,
  price,
  brand,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the product description page with the product's ID
    localStorage.setItem('product_id', uid);
    navigate(`/product/${uid}`, {
      state: {
        product: { id, uid, imageUrl, name, description, rating, price, brand },
      },
    });
  };

  return (
    <>
      <Tile>
        <div className="product-tile" onClick={handleClick}>
          <div className="product-image">
            <img
              src={imageUrl}
              alt={name}
              style={{ width: "200px", height: "auto" }} // Set the desired width and height
            />
          </div>
          <h4>{name}</h4>
          <div className="product-details">
            <div className="product-brand">
              <Tag type="purple" title="Clear Filter">
                {brand}
              </Tag>
            </div>
            <div className="product-rating">
              <Tag type="blue" title="Clear Filter">
                <div>
                  <StarFilled />
                  {rating === 0 ? "4.5" : rating}
                </div>
              </Tag>
            </div>
          </div>
          <div className="product-desc">{description}</div>
          <div className="product-price">
            {"$" + price}
            <div>
              <Tag type="green" title="Clear Filter">
                {id}
              </Tag>
            </div>
          </div>
          {/* <div className="product-price">{"$"+price + "   " + id}</div> */}
        </div>
      </Tile>
    </>
  );
}

export default ProductTile;
