import React, { useState } from "react";
import {
  Content,
  Header,
  HeaderName,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuButton,
  Theme,
} from "@carbon/react";
import "./app.scss";
import Homepage from "./pages/HomePage/Homepage";
import ProductDescPage from "./pages/ProductDescPage/ProductDescPage";
import ProductsPage from "./pages/ProductsPage/ProductsPage";
import { Provider } from "react-redux";
import { store } from "./store/Store";
import { Search, Notification, Switcher } from "@carbon/icons-react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HiddenHeader from "./components/HiddenHeader/HiddenHeader";

function App() {
  const [isSideNavExpanded, onClickSideNavExpand] = useState(true);
  const [query, setQuery] = useState("");
  const [tags, setTags] = useState([]);
  const [requestProduct, setRequestProduct] = useState(undefined);

  const onRequest = (product) => {
    setRequestProduct(product);
  };

  return (
    <BrowserRouter>
      <Theme theme="white">
        <Provider store={store}>
          <HiddenHeader
            query={query}
            setQuery={setQuery}
            tags={tags}
            setTags={setTags}
          />
          <Content>
            <Header aria-label="Header for Our Skeleton App">
              {/* <HeaderMenuButton
              aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
              onClick={onClickSideNavExpand}
              isActive={true}
              aria-expanded={isSideNavExpanded}
            /> */}
              <HeaderName
              //href="https://react.carbondesignsystem.com/"
              prefix=""
              >
                Intelligent Marketplace
              </HeaderName>
              <HeaderGlobalBar>
                <HeaderGlobalAction aria-label="Search" onClick={() => {}}>
                  <Search />
                </HeaderGlobalAction>
                <HeaderGlobalAction
                  aria-label="Notifications"
                  onClick={() => {}}
                >
                  <Notification />
                </HeaderGlobalAction>
                <HeaderGlobalAction
                  aria-label="App Switcher"
                  onClick={() => {}}
                >
                  <Switcher />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
            </Header>
            {/* <Navigation /> */}
            <Routes>
              <Route path="/" element={<Homepage setQuery={setQuery} />} />
              <Route
                path="/search/:query"
                element={
                  <ProductsPage
                    query={query}
                    setQuery={setQuery}
                    setTags={setTags}
                    onRequest={onRequest}
                  />
                }
              />
              <Route path="/product/:id" element={<ProductDescPage query={query} onRequest={onRequest}/>} />
              {/* <Route path="/homepage" element={<Homepage />} /> */}
            </Routes>
          </Content>
        </Provider>
      </Theme>
    </BrowserRouter>
  );
}

export default App;
