import React, { useState, useEffect } from "react";
import {
  Tile,
  Grid,
  Column,
  Tag,
  Layer,
  ProgressBar,
  ToastNotification,
} from "@carbon/react";
import {
  StarFilled,
  WarningFilled,
  CheckmarkFilled,
} from "@carbon/icons-react";

function ProductReview({ product, reviews }) {
  if (localStorage.getItem("isCompare")) localStorage.removeItem("isCompare");
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);

  let reviewsContent = null;

  const reviewsArray = reviews?.reviews;
  let pros = [];
  let cons = [];

  let removeDuplicates = (data) => {
    let unique = [];

    data.forEach((element) => {
      if (
        !unique.includes(
          element.enriched_body[0]?.keywords[0]?.text.toLowerCase()
        )
      )
        unique.push(element.enriched_body[0]?.keywords[0]?.text.toLowerCase());
    });
    return unique;
  };

  if (Array.isArray(reviews?.reviews)) {
    let keywordReviews = reviews?.reviews.filter(
      (r) =>
        r.enriched_body !== undefined &&
        r.enriched_body[0]?.keywords[0]?.text !== undefined &&
        r.enriched_body[0]?.keywords[0]?.text.toLowerCase().includes(" ")
    );

    let prosncons = removeDuplicates(keywordReviews);

    const stopWords = [
      "refrigerator",
      "fridge",
      "oven",
      "microwave",
      "washing machine",
      "washer",
      "front load",
      "top load",
      "home depot",
      "samsung",
      "ge",
      "lg",
    ];

    prosncons = prosncons.filter(
      (r) => !stopWords.some((term) => r.includes(term))
    );

    const stopWordsPros = ["bad", "poor", "worst"];

    pros = prosncons.filter(
      (r) => !stopWordsPros.some((term) => r.includes(term))
    );

    const stopWordsCons = [
      "good",
      "nice",
      "great",
      "best",
      "beautiful",
      "amazing",
    ];

    cons = prosncons.filter(
      (r) => !stopWordsCons.some((term) => r.includes(term))
    );
  } else {
    console.log("Reviews is not an array.");
  }

  // Array of random names
  const randomNames = [
    "John Doe",
    "Jane Smith",
    "David Johnson",
    "Emily Davis",
    "Brent",
    "Melissa Dais",
    "Jacob",
    "Adi Sharma",
    "Declan",
  ];

  // Function to get a random name from the array
  const getRandomName = () => {
    const randomIndex = Math.floor(Math.random() * randomNames.length);
    return randomNames[randomIndex];
  };

  // Function to generate random rating between 1 and 5
  const getRandomRating = (label) => {
    if (label === "positive") {
      return Math.floor(Math.random() * 5) + 1;
    } else {
      // For negative reviews, bias towards lower ratings
      return Math.floor(Math.random() * 3) + 1;
    }
  };

  if (Array.isArray(reviewsArray) && reviewsArray.length > 0) {
    reviewsContent = reviewsArray.map((review, index) => (
      <Column key={index} sm={2} md={4} lg={6}>
        <Layer>
          <Tile className="review-tile">
            <span className="user-details">
              <strong>{getRandomName()}</strong>{" "}
              <span className="rating">
                {[
                  ...Array(
                    getRandomRating(review.enriched_body[0]?.sentiment.label)
                  ),
                ].map((_, i) => (
                  <StarFilled key={i} />
                ))}
              </span>
            </span>

            {review.body[0]}
          </Tile>
        </Layer>
      </Column>
    ));
  } else {
    reviewsContent = <p>No reviews available</p>;
  }

  useEffect(() => {
    // Add the code snippet here to count positive and negative reviews
    if (Array.isArray(reviewsArray) && reviewsArray.length > 0) {
      let posCount = 0;
      let negCount = 0;

      reviewsArray.forEach((review) => {
        if (
          review.enriched_body &&
          review.enriched_body.length > 0 &&
          review.enriched_body[0].sentiment &&
          review.enriched_body[0].sentiment.label
        ) {
          if (review.enriched_body[0].sentiment.label === "positive") {
            posCount++;
          } else if (review.enriched_body[0].sentiment.label === "negative") {
            negCount++;
          }
        }
      });

      setPositiveCount(posCount);
      setNegativeCount(negCount);
    }
  }, [reviewsArray]);

  return (
    <Grid narrow>
      <Column sm={2} md={4} lg={12}>
        <Tile className="product-reviews-desc-tile">
          <Grid narrow>
            <Column sm={2} md={2} lg={4}>
              <img src={product.image} alt={product.name} width="250px" />
            </Column>
            <Column sm={2} md={4} lg={8}>
              <div className="product-desc-tile">
                <h3>{product.brand + " " + product.title}</h3>
                <div className="product-price-rating">
                  <h2 className="product-price">{"$" + product.price}</h2>
                  <div className="product-rating">
                    <Tag type="blue" title="Clear Filter">
                      <div>
                        <StarFilled />
                        {product.hasOwnProperty("rating")
                          ? product.rating
                          : "4.5"}
                      </div>
                    </Tag>
                  </div>
                </div>
                <div>
                  <Grid narrow>
                    <Column sm={2} md={4} lg={3}>
                      <Tile className="reviews-tile">
                        <h5> Pros </h5>
                        {/* Fetch the list from API and print here */}
                        <div className="pros-list">
                          <ul>
                            {pros.slice(0, 3).map((r, index) => (
                              <li key={index}>
                                {
                                  <div className="pros-content">
                                    <CheckmarkFilled className="pros" />
                                    {r}
                                  </div>
                                }
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Tile>
                    </Column>
                    <Column sm={2} md={4} lg={3}>
                      <Tile className="reviews-tile">
                        <h5> Cons </h5>
                        <div className="cons-list">
                          <ul>
                            {cons.slice(0, 3).map((r, index) => (
                              <li key={index}>
                                {
                                  <div className="cons-content">
                                    <WarningFilled className="cons" />
                                    {r}
                                  </div>
                                }
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Tile>
                    </Column>
                  </Grid>
                </div>
              </div>
            </Column>
          </Grid>
        </Tile>
      </Column>
      <Column sm={2} md={4} lg={12}>
        <Tile className="product-reviews-highlights">
          <Grid narrow>
            <Column sm={4} md={8} lg={12}>
              {" "}
              <h5 className="product-reviews-heading">
                Customer Reviews Summary
              </h5>
            </Column>
            <Column sm={2} md={4} lg={6} className="review-progress-bar">
              <Layer>
                {/* <ProgressBar label={<span>5 <StarFilled /></span>} value={75} /> */}
                <ProgressBar label="5 stars" value={75} />
                <ProgressBar label="4 stars" value={50} />
                <ProgressBar label="3 stars" value={10} />
                <ProgressBar label="2 stars" value={3} />
                <ProgressBar label="1 stars" value={1} />
              </Layer>
            </Column>
            <Column sm={2} md={2} lg={3}>
              <ToastNotification
                hideCloseButton
                kind="success"
                lowContrast
                role="status"
                subtitle={positiveCount}
                title="Positive Reviews"
              />
            </Column>
            <Column sm={2} md={2} lg={3}>
              <ToastNotification
                hideCloseButton
                kind="warning"
                lowContrast
                role="status"
                subtitle={negativeCount}
                title="Negative Reviews"
              />
            </Column>
            <Column sm={2} md={4} lg={6} className="product-top-features">
              <h6>Top Features</h6>
              <Tile className="product-top-reviews">{reviewsContent[0]}</Tile>
            </Column>
            <Column sm={2} md={4} lg={6} className="product-top-issues">
              <h6>Top Issue</h6>
              <Tile className="product-top-reviews">
                {reviewsContent[reviewsContent.length - 1]}
              </Tile>
            </Column>
          </Grid>
        </Tile>
      </Column>
      <Column sm={2} md={4} lg={12}>
        {/* Print alll review body here */}
        <Tile className="product-reviews-list">
          <h5>Customer Reviews</h5>
          <Grid narrow>{reviewsContent}</Grid>
        </Tile>
      </Column>
      {/* <button style={{ display: "none" }} id="hiddenBtnProdQuery">
        Hidden Button
      </button> */}
    </Grid>
  );
}

export default ProductReview;
