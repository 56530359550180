import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Grid,
  Column,
  Breadcrumb,
  BreadcrumbItem,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@carbon/react";
import ProductTile from "../../components/ProductTile/ProductTile";
import ProductDescPage from "../ProductDescPage/ProductDescPage";
import PageHeader from "../../components/PageHeader/PageHeader";
// import jsonData from "./jsonData.json";

function ProductsPage({ query, setQuery, setTags, onRequest }) {
  const [tileData, setTileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirectProd, setRedirectProd] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductReviews, setSelectedProductReviews] = useState({});

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const { query: paramsQuery } = useParams();
  const [searchParams] = useSearchParams();

  const setSearchState = (query, filterParam, filters) => {
    //setLoading(true);
    setProducts([]);
    setFilteredProducts([]);
    // fetchProducts(query, filterParam, filters);
  };

  // Generates the product tiles on the product list page
  useEffect(() => {
    if (paramsQuery) setQuery(paramsQuery);
    const filterParam = searchParams.get("filters");
    let filters = { ratings: [] };
    if (filterParam) filters = JSON.parse(filterParam);
    console.log("filterparmasss -> ".query, "+", filters)
    setSearchState(query, filterParam, filters);

    const storedResponse = localStorage.getItem("responsejson");
    if (storedResponse) {
      const response = JSON.parse(storedResponse);
      const resultsObject = response.find(
        (response) => response.response_type === "search"
      )?.results;

      if (resultsObject && resultsObject.products) {
        const dynamicTileData = resultsObject.products.map(
          (product, index) => ({
            id: index + 1,
            uid: product.id,
            imageUrl: product.image,
            name: product.title,
            description: product.description,
            rating: product.rating || 0,
            price: product.price,
            brand: product.brand,
          })
        );
        setTileData(dynamicTileData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Update the query state when paramsQuery changes
    if (paramsQuery) setQuery(paramsQuery);

    // Get the "filters" parameter from the URL
    const filterParam = searchParams.get("filters");
    let filters = { ratings: [] };
    if (filterParam) filters = JSON.parse(filterParam);

    // Perform any action or fetch data based on the URL/query changes
    console.log("URL or query parameters changed -> ", query, "+", filters);
    setLoading(true);
    // Call your setSearchState or any other action based on the changes
    setSearchState(query, filterParam, filters);
    const storedResponse = localStorage.getItem("responsejson");
    if (storedResponse) {
      const response = JSON.parse(storedResponse);
      const resultsObject = response.find(
        (response) => response.response_type === "search"
      )?.results;

      if (resultsObject && resultsObject.products) {
        const dynamicTileData = resultsObject.products.map(
          (product, index) => ({
            id: index + 1,
            uid: product.id,
            imageUrl: product.image,
            name: product.title,
            description: product.description,
            rating: product.rating || 0,
            price: product.price,
            brand: product.brand,
          })
        );
        setTileData(dynamicTileData);
        setLoading(false);
      }
    }
    // The rest of your existing code for fetching and updating state
  }, [paramsQuery, searchParams, query]);

  return (
    <div className="product-desc-page">
      {redirectProd ? (
        <></>
        // <ProductDescPage
        //   product={selectedProduct}
        //   reviews={selectedProductReviews}
        // />
      ) : (
        <div>
          <Grid narrow>
            <Column sm={4} md={8} lg={16}>
              <PageHeader headerTitle={"Appliances"} isProductsPage={true} isProductDescPage={false} />
              <Tabs>
                <TabList aria-label="List of tabs">
                  <Tab>Search Results</Tab>
                  <Tab disabled>All Categories</Tab>
                  <Tab disabled>Today's Deals</Tab>
                  <Tab disabled>Best Selling</Tab>
                  <Tab disabled>New Releases</Tab>
                  <Tab disabled>Featured</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Grid className="product-tiles">
                      {" "}
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        tileData.map((tile, index) => (
                          <Column sm={4} md={3} lg={4}>
                            {/* <Link to={`/product/${index + 1}`}> */}
                            <ProductTile key={index} {...tile} />
                            {/* </Link> */}
                          </Column>
                        ))
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel>Tab Panel 2</TabPanel>
                  <TabPanel>Tab Panel 3</TabPanel>
                  <TabPanel>Tab Panel 4</TabPanel>
                </TabPanels>
              </Tabs>
            </Column>
          </Grid>
          {/* <button style={{ display: 'none' }} id="hiddenBtnProd">Hidden Button</button> */}
        </div>
      )}
    </div>
  );
}

export default ProductsPage;
